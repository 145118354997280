@charset "utf-8";
@import '../base';

#wholesale {
    h1 {
        font-size: 26px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 22px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    #working_with_us_request {
        background: #f5f5f5;

        .form-control {
            margin-bottom: 5px;
            height: 50px;
            padding: 15px;
            border-color: transparent;
            box-shadow: 0 0 10px rgb(0 0 0 / 1%);
            border-radius: $default-border-radius;
            border-width: 2px;

            &:focus {
                border: 2px solid #000;
            }
        }

        .btn-outline-secondary {
            font-size: 20px;
            line-height: 36px;
            color: rgba(47, 47, 49, 0.9);
            text-transform: uppercase;
            background-color: transparent;
            border-radius: $default-border-radius;
            width: 100%;
            border-color: rgba(47, 47, 49, 0.9);
            margin-bottom: 15px;
        }
    }
}
