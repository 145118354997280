.select-ctr {
    position: relative;

    .input-preview {
        color: #000000;
        display: inline-block;
        transition: .3s all ease;
        padding: 0;
        font-size: 14px;
        width: auto;
        position: relative;
        border-bottom: 1px dashed #000080;
        cursor: pointer;
        user-select: none;

        &.active, &:hover {
            color: #f16101;
            border-bottom: 1px dashed #f16101;
        }
    }

    $num: 10;
    @while $num >= 0 {
        .input-#{$num} {
            top: #{30 + $num * 50}px;
        }
        $num: $num - 1;
    }

    .input {
        opacity: 0;
        background: #fff;
        color: #777;
        box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.1);
        padding: 10px 15px;
        position: absolute;
        width: 100%;
        font-size: 16px;
        cursor: pointer;
        z-index: 999;

        &.active {
            color: #f16101;
        }
    }
}
