$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);

@mixin respond-to($media) {
    @if $media == sm {
        @media (max-width: $screen-sm-max) {
            @content
        }
    } @else if $media == md {
        @media (min-width: $screen-md) and (max-width: $screen-md-max) {
            @content
        }
    } @else if $media == lg {
        @media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
            @content
        }
    } @else if $media == xl {
        @media (min-width: $screen-xl) {
            @content
        }
    }
}

@mixin min-width($media) {
    @if $media == sm {
        @media (min-width: $screen-sm) { @content }
    }
    @else if $media == md {
        @media (min-width: $screen-md) { @content }
    }
    @else if $media == lg {
        @media (min-width: $screen-lg) { @content }
    }
    @else if $media == lg {
        @media (min-width: $screen-lg) { @content }
    }
    @else {
        @media (min-width: $media) { @content }
    }
}

@mixin max-width($media) {
    @if $media == sm {
        @media (max-width: $screen-sm - 1) { @content }
    }
    @else if $media == md {
        @media (max-width: $screen-md - 1) { @content }
    }
    @else if $media == lg {
        @media (max-width: $screen-lg - 1) { @content }
    }
    @else if $media == xl {
        @media (max-width: $screen-xl - 1) { @content }
    }
    @else {
        @media (max-width: $media) { @content }
    }
}

%MontserratExtraBold {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-style: normal;
}

%MontserratMedium {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
}

%MontserratLight {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-style: normal;
}

%MontserratSemiBold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
}

%owl-carousel {
    .owl-carousel {
        position: relative;

        .owl-dots {
            position: absolute;
            bottom: 24px;
            left: 20px;
            right: 110px;
            text-align: left;
            @include max-width(sm)  {
                display: none;
            }

            .owl-dot {
                display: inline-block;
                width: 60px;
                height: 13px;
                position: relative;
                outline: none;

                span {
                    display: none;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: 10px;
                    background: rgba(213, 224, 245, 0.8);
                    width: 40px;
                    height: 3px;
                    transition: background 0.3s;
                }

                &.active:after {
                    background: #d64047;
                }

                &:not(.active):hover:after {
                    background: rgb(254, 253, 255);
                }
            }
        }

        .owl-nav {
            position: absolute;
            right: 20px;
            bottom: 10px;
            margin-top: 0;

            .owl-prev, .owl-next {
                transition: all 0.3s linear;
                text-align: center;
                position: relative;
                z-index: 100;
                outline: none;
                width: 55px;
                height: 40px;
                background: rgba(255, 255, 255, 0.6);
                border-radius: 0;
                margin: 0;

                &:hover {
                    background: rgba(255, 255, 255, 1);
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 16px;
                    width: 20px;
                    height: 20px;
                }
            }

            .owl-prev {
                border-right: 1px solid #dfe9f0;
            }

            .owl-prev:before {
                background: url(../images/arrow-left.svg) no-repeat !important;
            }

            .owl-next:before {
                background: url(../images/arrow-right.svg) no-repeat !important;
            }
        }
    }
}
