@charset "utf-8";
@import '../base';

#guarantee {
    h1 {
        font-size: 26px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    p.text {
        margin-bottom: 30px;
    }

    #guarantee_backcall {
        margin-top: 0;
        margin-bottom: 30px;
        border-radius: 5px;
        overflow: hidden;
        background: transparent linear-gradient(54deg, rgb(44 184 247 / 95%) 34%, rgb(226 79 251 / 95%)) 0% 0% no-repeat padding-box;
        position: relative;
        padding: 100px 0;
        z-index: 1;
        @include max-width(lg) {
            padding: 50px 0;
        }

        .block {
            display: flex;
            height: 100%;
            margin: 0 40px;
            @include max-width(lg) {
                text-align: center;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.9);
                margin: auto 0;
            }

            .btn {
                @extend %MontserratSemiBold;
                background-color: #fff;
                padding: 12px 40px;
                border-radius: 35px;
                font-size: 24px;
                line-height: 40px;
                color: rgba(#3a3a3a, 0.9);
                text-transform: uppercase;
                margin: auto 0;
                border-color: rgba(255, 255, 255, 0.9);
                transition: all 0.3s linear;
                @include max-width(xl) {
                    font-size: 18px;
                }
                @include max-width(lg) {
                    margin: 30px auto 10px auto;
                    padding: 10px 30px;
                }
                @include max-width(md) {
                    font-size: 16px;
                    padding: 6px 16px;
                }

                &:hover {
                    box-shadow: 0 4px 7px rgb(110 110 110 / 30%);
                }

                &:focus, &.focus {
                    box-shadow: none;
                }
            }
        }
    }
}
