@import '../variables';
@import "../base";

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #f16101;
    border-bottom: 16px solid #f16101;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    .mfp-close {
        display:none;
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
