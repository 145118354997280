@import '../variables';
@import "../base";

.popup-modal {
    position: relative;

    .block {
        background-color: #fdfdfd;
        padding: 70px 80px;
        box-shadow: 0 0 10px rgba(0,0,0,.5);
        max-width: 600px;
        @include max-width(sm) {
            padding: 20px 30px;
        }

        h3 {
            color: #f16101;
            padding-top: 0;
            text-align: center;
            font-size: 34px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .btn {
            color: #602700;
            background: #ffffff;
            border-color: #f16101;
            padding: 8px 45px;
            text-transform: uppercase;
            border-radius: 4px;
            font-size: 18px;
            margin-top: 30px;
            border-width: 3px;
            @extend %MontserratExtraBold;

            &:focus {
                box-shadow: none;
            }
        }

        input[type="tel"], input[type="text"] {
            text-align: center;
            margin-bottom: 10px;
            color: #000;
            border: none;
            border-radius: 0;
            outline: none transparent !important;
            background-color: transparent;
            padding: 0 10px;
            border-bottom: 2px solid #e1e1e1;
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1);
            @extend %MontserratLight;
        }

        .privacy {
            font-size: 11px;
            margin-top: 20px;
            margin-bottom: 0;
            text-align: center;
            @extend %MontserratLight;
        }
    }

    .mfp-close {
        right: 15px;
        color: #000 !important;
    }
}
