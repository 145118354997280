@charset "utf-8";
@import '../base';

#map {
    width: 100%;
    height: 450px;
    padding: 0;
    margin: 0;
    border-top: solid #BEBEBE 1px;
    @include max-width(md) {
        border-top: none;
    }
}

#contacts {
    position: relative;
    box-shadow: none;

    h1 {
        font-size: 26px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    #map_block {
        margin-bottom: 30px;

        .details {
            height: 100%;
            background-color: white;
            border-top: solid #BEBEBE 1px;

            .nav-tabs {
                border: none;
                border-radius: 0;

                .nav-item {
                    width: 50%;
                    text-align: center;

                    a {
                        @extend %MontserratSemiBold;
                        color: white;
                    }

                    a.show, a.active {
                        color: black;
                    }
                }

                .nav-link {
                    border: none;
                    border-radius: 0;
                }

                #moscow-tab {
                    background-color: #2f2f2fa1;
                }

                #bryansk-tab {
                    background-color: #2f2f2fa1;
                }

                .show, .active {
                    background-color: white !important;
                }
            }

            #contactsTabContent {
                .contacts-row {
                    padding: 10px 25px 5px 25px;
                    border-bottom: 1px solid #e7e7e7;
                }

                p {
                    margin-bottom: 0;
                }

                a {
                    color: black;
                }

                .title {
                    color: rgba(0, 0, 0, 0.9);
                    font-size: 12px;
                    text-transform: uppercase;
                }

                .content {
                    margin-top: 4px;
                    color: rgba(0, 0, 0, 0.9);
                    font-size: 16px;
                }
            }
        }
    }

    .advantages-box {
        border-radius: 0;
    }

    #contact_us {
        background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: rgb(30, 30, 30); stroke-width: 4; stroke-dasharray: 10% 10%; stroke-dashoffset: 5%'/></svg>");

        .form-control {
            margin-bottom: 5px;
            height: 50px;
            padding: 15px;
            border-color: transparent;
            box-shadow: 0 0 10px rgb(0 0 0 / 1%);
            border-radius: 0;
            border-width: 2px;

            &:focus {
                border: 2px solid #000;
            }
        }

        .custom-file-input {
            height: 50px;
        }

        .custom-file-label {
            margin-bottom: 5px;
            height: 50px;
            padding: 15px;
            border-color: transparent;
            box-shadow: 0 0 10px rgb(0 0 0 / 1%);
            border-radius: 0;
            border-width: 2px;


            display: block;
            width: 100%;
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.6;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:after {
                content: "Обзор";
                height: 50px;
                padding: 15px;
            }

            &:focus {
                border: 2px solid #000;
            }
        }

        .btn-outline-secondary {
            font-size: 20px;
            line-height: 36px;
            color: rgba(47, 47, 49, 0.9);
            text-transform: uppercase;
            background-color: transparent;
            border-radius: 0;
            width: 100%;
            border-color: rgba(47, 47, 49, 0.9);
            margin-bottom: 15px;
        }
    }

    #kitchen_calculation {
        background: transparent linear-gradient(240deg, #192ecc 0%, #de1f1f 100%) 0% 0% no-repeat padding-box;
        position: relative;
        padding: 100px 0;
        z-index: 1;
        @include max-width(lg) {
            padding: 50px 0;
        }

        &:after {
            content: '';
            background: url(../../images/kitchen-calculation-bg.png) no-repeat bottom right;
            background-size: cover;
            position: absolute;
            z-index: 2;
            right: 0;
            bottom: 0;
            height: 250px;
            width: 325px;
            @include max-width(lg) {
                display: none;
            }
        }

        p {
            font-size: 20px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.9);
            margin-bottom: 0;
            @include max-width(lg) {
                text-align: center;
            }
        }

        .block {
            display: flex;
            height: 100%;
            @include max-width(lg) {
                text-align: center;
            }

            .btn {
                font-size: 24px;
                line-height: 40px;
                color: rgba(255, 255, 255, 0.9);
                text-transform: uppercase;
                background-color: transparent;
                border-radius: $default-border-radius;
                margin-top: 20px;
                border-color: rgba(255, 255, 255, 0.9);
                transition: all 0.3s linear;
                @include max-width(xl) {
                    font-size: 18px;
                }
                @include max-width(lg) {
                    margin-left: auto;
                    margin-right: auto;
                }
                @include max-width(md) {
                    font-size: 16px;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.9);
                    color: #2f2f2f;
                }

                &:focus, &.focus {
                    box-shadow: none;
                }
            }
        }
    }

}

