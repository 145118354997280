#custom_topnavbar {
    position: relative;
    background-color: #2F2F2F;
    box-shadow: 0 2px 4px rgba(86, 86, 86, 0.3);

    .links {
        margin: 0 auto;
        text-align: center;

        a {
            color: #DCD3D3;
            font-size: 16px;
            line-height: 32px;
            display: inline-block;
            transition: color .4s ease;
            @extend %MontserratMedium;
            @include max-width(lg) {
                font-size: 14px;
                line-height: 38px;
            }

            &:hover {
                color: #f16101;
            }
        }

        li {
            display: inline-block;
        }

        li + li {
            margin-left: 25px;
        }
    }

    #topnavbar {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .contact {
        //padding-right: 0;

        .phone-number {
            font-size: 16px;
            color: #DCD3D3;
            transition: color .4s ease;

            &:hover {
                color: #f16101;

                span {
                    color: #f16101;
                }
            }

            span {
                color: #eae9e9;
                transition: color .4s ease;
            }
        }
    }

    .navbar-toggler {
        border: none;
        outline: none;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' " +
                    "height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' " +
                    "stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }

    #dropdown_topnavbar {
        padding-right: inherit;
        position: absolute;
        z-index: 999;
        right: 0;
        top: 120%;
        //for gsap
        opacity: 0;
        visibility: hidden;
        transform: scale(1.2, 1.2);

        .box {
            background-color: #2F2F2F;
            box-shadow: 0 2px 4px rgba(86, 86, 86, 0.3);
        }

        .links {
            padding: 0;

            li {
                margin: 0;
                padding: 10px 35px;
                font-size: 16px;
                display: block;
                text-align: right;
            }
        }
    }
}
