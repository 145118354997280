@charset "utf-8";
@import '../base';

#index {
    #slide_index {
        position: relative;
        padding-bottom: 20px;

        #slide_index_card {
            display: flex;
            position: absolute;
            left: 0;
            right: -2px;
            height: 100%;
            background-color: #2f2f2f;
            min-height: 250px;
            z-index: 3;
            border-top-left-radius: $default-border-radius;
            border-bottom-left-radius: $default-border-radius;

            &:before {
                content: '';
                position: absolute;
                top: 4px;
                display: block;
                background: url(../../images/frame-edge.png) no-repeat;
                background-size: cover;
                transform: rotate(270deg);
                height: 100px;
                width: 100px;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 4px;
                right: 0;
                display: block;
                background: url(../../images/frame-edge.png) no-repeat;
                background-size: cover;
                transform: rotate(90deg);
                height: 100px;
                width: 100px;
            }

            .item-card {
                padding: 65px;
                margin: auto;

                h3 {
                    font-size: 30px;
                    line-height: 36px;
                    margin-bottom: 15px;
                    color: #F7F7F7;
                    text-align: center;

                    @include max-width(xl) {
                        font-size: 24px;
                    }
                }

                .form-control {
                    text-align: center;
                    margin-bottom: 5px;
                    height: 43px;
                    padding: 15px;
                    border: none;
                    box-shadow: 0 0 15px rgb(255 255 255 / 93%);
                    border-radius: 3px;
                    color: rgba(0, 0, 0, 0.9);
                }

                .btn-outline-secondary {
                    @extend %MontserratSemiBold;
                    font-size: 17px;
                    line-height: 29px;
                    color: #F7F7F7;
                    text-transform: uppercase;
                    background-color: transparent;
                    width: 100%;
                    border-color: #F7F7F7;
                    margin-bottom: 15px;
                    border-radius: 3px;
                    transition: all 0.3s linear;

                    &:hover {
                        background-color: #F7F7F7;
                        color: #2f2f2f;
                    }

                    &:focus, &.focus {
                        box-shadow: none;
                    }

                    @include max-width(xl) {
                        font-size: 14px;
                    }
                }
            }
        }

        .item {
            background: #F7F7F7;
            box-shadow: 0 3px 10px rgba(86, 86, 86, 0.6);
            margin: 0;

            .item-img {
                overflow: hidden;
                height: 100%;

                img {
                    height: 100%;
                }
            }
        }

        .owl-carousel {
            padding-right: 0;
            padding-left: 0;

            .owl-item {
                overflow: hidden;
                border-top-right-radius: $default-border-radius;
                border-bottom-right-radius: $default-border-radius;

                &.active {
                    display: block;
                }
            }
        }

        @extend %owl-carousel;
    }

    #posters {
        padding-bottom: 20px;

        @include max-width(md) {
            .row > div:not(:first-child) {
                margin-top: 20px;
            }
        }

        .card {
            border: 0;
            border-radius: $default-border-radius;
            box-shadow: 0 3px 10px rgba(86, 86, 86, 0.6);
            padding: 30px 40px 20px 30px;
            min-height: 400px;
            background-size: cover;
            display: flex;
            justify-content: flex-end;
            @include max-width(lg)  {
                min-height: 330px;
            }

            h4 {
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 25px;
                color: #F7F7F7;
                @include max-width(lg)  {
                    margin-bottom: 15px;
                }

                span {
                    font-size: 48px;
                    line-height: 72px;
                    color: #F7F7F7;
                    text-transform: uppercase;
                    display: block;
                    @include max-width(lg) {
                        font-size: 28px;
                        line-height: 34px;
                    }
                    @include max-width(md) {
                        font-size: 38px;
                        line-height: 52px;
                    }
                    @include max-width(sm) {
                        font-size: 28px;
                        line-height: 30px;
                    }
                }
            }

            p {
                font-size: 16px;
                line-height: 19px;
                color: #F7F7F7;

                &.small {
                    font-size: 11px;
                    line-height: 13px;
                    margin-bottom: 0;
                }
            }
        }
    }

    #advantages {
        margin-bottom: 5px;

        .head {
            margin-bottom: 20px;
            @include max-width(md) {
                text-align: center;
            }

            > div {
                display: flex;
            }

            h1 {
                font-size: 24px;
                line-height: 30px;
                color: rgba(0, 0, 0, 0.9);
                text-align: right;
                margin: auto 0 auto auto;
                @include max-width(lg) {
                    font-size: 20px;
                    line-height: 26px;
                }
                @include max-width(md) {
                    margin: 0 30px 15px 30px;
                    text-align: left;
                }
            }

            .separation {
                height: 100%;
                width: 1px;
                padding: 0 2px;
                background: #DE0000;
                margin: 0 30px;
                @include max-width(lg) {
                    margin: 0 20px;
                }
            }

            p {
                @extend %MontserratMedium;
                font-size: 16px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
                margin: auto 0;
                @include max-width(lg) {
                    font-size: 14px;
                    line-height: 17px;
                }
                @include max-width(md) {
                    margin: 0 30px 0 30px;
                }
            }
        }

        .body {
            .block {
                background: #FFFFFF;
                border-radius: $default-border-radius;
                box-shadow: 0 3px 10px rgba(86, 86, 86, 0.1);
                margin-bottom: 15px;
                @include max-width(md) {
                    text-align: left;
                }

                img {
                    max-height: 150px;
                    padding: 15px 15px 25px 15px;
                    @include max-width(md) {
                        max-height: 200px;
                        padding: 15px 30px 25px 30px;
                    }
                }

                h3 {
                    font-size: 24px;
                    line-height: 29px;
                    color: rgba(0, 0, 0, 0.9);
                    padding: 0 15px 0 15px;
                    @include max-width(md) {
                        padding: 0 30px 0 30px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 18px;
                    color: rgba(0, 0, 0, 0.9);
                    padding: 5px 15px 15px 15px;
                    margin-bottom: 0;
                    @include max-width(md) {
                        padding: 5px 30px 15px 30px;
                    }
                }
            }
        }
    }

    #kitchen_catalog {
        h2 {
            font-size: 26px;
            line-height: 30px;
            color: rgba(0, 0, 0, 0.9);
            text-align: center;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .btn {
            @extend %MontserratSemiBold;
            font-size: 18px;
            line-height: 40px;
            color: rgba(47, 47, 49, 0.9);
            text-transform: uppercase;
            background-color: transparent;
            border-radius: $default-border-radius;
            border-color: rgba(47, 47, 49, 0.9);
            transition: all 0.3s linear;
            @include max-width(md) {
                margin: 10px auto 10px auto;
                font-size: 18px;
            }

            &:hover {
                background-color: rgba(47, 47, 49, 0.9);
                color: #f7f7f7;
            }

            &:focus, &.focus {
                box-shadow: none;
            }
        }
    }

    #measurement {
        background: url(../../images/callback-bg.png) no-repeat;
        background-size: cover;
        padding: 100px 0;
        margin-bottom: 20px;

        @include max-width(md) {
            padding: 50px 0;
        }

        .roulette-img {
            text-align: center;
            padding: 0 30px;
            margin-bottom: 40px;
            @include max-width(md) {
                margin-bottom: 20px;
                padding: 0;
            }
        }

        .block {
            height: 100%;
            display: flex;
            @include max-width(md) {
                text-align: center;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.9);
                margin-bottom: 0;
            }

            .btn {
                @extend %MontserratSemiBold;
                font-size: 18px;
                line-height: 40px;
                color: rgba(255, 255, 255, 0.9);
                text-transform: uppercase;
                background-color: transparent;
                border-radius: $default-border-radius;
                margin: auto 0;
                border-color: rgba(255, 255, 255, 0.9);
                transition: all 0.3s linear;
                @include max-width(md) {
                    margin: 20px auto 0 auto;
                    font-size: 18px;
                }
                @include max-width(sm) {
                    font-size: 14px;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.9);
                    color: #2f2f2f;
                }

                &:focus, &.focus {
                    box-shadow: none;
                }
            }
        }
    }

    #review {
        margin-bottom: 20px;

        .head {
            margin-bottom: 20px;
            @include max-width(md) {
                text-align: center;
            }

            > div {
                display: flex;
            }

            h2 {
                font-size: 24px;
                line-height: 30px;
                color: rgba(0, 0, 0, 0.9);
                text-align: right;
                margin: auto 0 auto auto;
                @include max-width(lg) {
                    font-size: 20px;
                    line-height: 26px;
                }
                @include max-width(md) {
                    margin: 0 30px 15px 30px;
                    text-align: left;
                }
            }

            .separation {
                height: 100%;
                width: 1px;
                padding: 0 2px;
                background: #DE0000;
                margin: 0 30px;
                @include max-width(lg) {
                    margin: 0 20px;
                }
            }

            p {
                font-size: 16px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
                margin: auto 0;
                padding: 10px 0;
                @include max-width(lg) {
                    font-size: 14px;
                    line-height: 17px;
                }
                @include max-width(md) {
                    margin: 0 30px 0 30px;
                    padding: 0;
                }
            }
        }

        .body {
            .item {
                text-align: center;
                position: relative;
                padding-top: 30px;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                border-radius: $default-border-radius;

                .youtube {
                    background-position: center;
                    background-repeat: no-repeat;
                    position: relative;
                    display: inline-block;
                    overflow: hidden;
                    transition: all 200ms ease-out;
                    cursor: pointer;

                    .play {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        opacity: .8;
                        filter: alpha(opacity=80);
                        transition: all 0.2s ease-out;
                        &:before {
                            position: absolute;
                            content: '';
                            background: #4592DA;
                            display: block;
                            height: 3px;
                            width: 100%;
                            top: 0;
                        }
                        &:after {
                            position: absolute;
                            content: '';
                            background: url('../../images/youtube-play.svg') no-repeat center center;
                            display: block;
                            height: 83px;
                            width: 83px;
                            right: 10px;
                            bottom: 10px;
                        }

                        &:hover {
                            opacity: 1;
                            filter: alpha(opacity=100);
                        }
                    }
                }

                iframe, .youtube {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 5px 65px;
                }
            }

            .owl-carousel {
                position: relative;

                .owl-dots {
                    text-align: center;

                    .owl-dot {
                        display: inline-block;
                        width: 60px;
                        height: 13px;
                        position: relative;
                        outline: none;

                        span {
                            display: none;
                        }

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 5px;
                            left: 10px;
                            background: rgba(213, 224, 245, 0.8);
                            width: 40px;
                            height: 3px;
                            transition: background 0.3s;
                        }

                        &.active:after {
                            background: #d64047;
                        }

                        &:not(.active):hover:after {
                            background: rgb(171, 182, 203);
                        }
                    }
                }
            }
        }
    }

    #closet_catalog {
        h2 {
            font-size: 26px;
            line-height: 30px;
            color: rgba(0, 0, 0, 0.9);
            text-align: center;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .btn {
            @extend %MontserratSemiBold;
            font-size: 18px;
            line-height: 40px;
            color: rgba(47, 47, 49, 0.9);
            text-transform: uppercase;
            background-color: transparent;
            border-radius: $default-border-radius;
            border-color: rgba(47, 47, 49, 0.9);
            transition: all 0.3s linear;
            @include max-width(md) {
                margin: 10px auto 10px auto;
                font-size: 18px;
            }

            &:hover {
                background-color: rgba(47, 47, 49, 0.9);
                color: #f7f7f7;
            }

            &:focus, &.focus {
                box-shadow: none;
            }
        }
    }

    #works {
        padding-top: 60px;

        .heading {
            text-align: center;

            .title {
                display: inline-block;
                font-weight: 500;
                font-size: 30px;
                color: #111;
            }
        }
    }

    #gift {
        background-color: #1d1d1d;
        position: relative;

        #giftParticles {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
        }

        .body {
            padding: 130px 50px 130px 50px;
            text-align: center;
            font-size: 24px;

            span {
                background-color: #ffffff;
                line-height: 1.4;
            }
        }

        .btn-success {
            background-color: #ffffff;

            &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle, &:hover {
                background-color: #F8433b;
                border-color: #F8433b;
            }
        }
    }

    #map {
        .block {
            padding-right: 0;
            padding-left: 0;
            position: relative;

            .info {
                position: absolute;
                z-index: 100;
                top: -50px;
                left: 10px;
                background: white;
                padding: 0 20px;
                color: #212529;
                box-shadow: 0 0 10px rgba(0,0,0,.5);
                @include max-width(md) {
                    position: static;
                    box-shadow: none;
                    text-align: center;
                    padding: 20px;
                }

                a {
                    font-size: 21px;
                    font-weight: 700;
                    color: #212529;
                }
            }

            #moscowMap {
                height: 500px;
                border-right: #1d1d1d solid 4px;
                border-left: #1d1d1d solid 8px;
                @include max-width(lg) {
                    border: none;
                }
            }

            #bryanskMap {
                height: 500px;
                border-left: #1d1d1d solid 4px;
                border-right: #1d1d1d solid 8px;
                @include max-width(lg) {
                    border: none;
                }
            }
        }
    }
}


