@import '../variables';
@import "../base";

.catalog-cards {
    .prev-img {
        filter: drop-shadow(0px 3px 10px rgba(86, 86, 86, 0.1));
        overflow: hidden;
    }

    .description {
        margin: 10px 30px 20px 30px;
        text-align: center;

        .name {
            bottom: 0;
            float: left;
            text-align: left;

            a {
                @extend %MontserratSemiBold;
                font-size: 24px;
                color: rgba(0, 0, 0, 0.8);
                text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
                transition: all 0.4s ease;

                &:hover {
                    color: #f16101;
                }
            }
        }

        .price {
            bottom: 0;
            display: inline-block;
            text-align: right;
            float: right;

            span {
                @extend %MontserratLight;
                white-space: nowrap;
                display: inline-block;
                margin-bottom: 0;
                font-size: 22px;
                color: #000000;
            }
        }

        .about {
            @extend %MontserratSemiBold;
            border: 1px solid #8E7E7E;
            text-transform: uppercase;
            background-color: transparent;
            border-radius: $default-border-radius;
            font-size: 18px;
            line-height: 40px;
            color: #000000;
            min-width: 250px;
            margin-top: 15px;
            transition: all 0.4s ease;
            @include max-width(lg) {
                font-size: 20px;
                line-height: 36px;
                min-width: 220px;
            }

            &:hover {
                border: 1px solid #f16101;
                color: #f16101;
            }
        }
    }

    .owl-carousel {
        padding-right: 0;
        padding-left: 0;

        .owl-item {
            overflow: hidden;
            border-radius: $default-border-radius;

            &.active {
                display: block;
            }
        }

        .owl-dots {
            left: 15px !important;
            right: 15px !important;
            text-align: center !important;
        }
    }

    @extend %owl-carousel;
}
