@charset "utf-8";
@import '../base';

#kitchen {
    h1 {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.8);
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    }

    .price {
        @extend %MontserratLight;
        bottom: 0;
        font-size: 16px;

        span {
            @extend %MontserratLight;
            margin-bottom: 0;
            color: #000000;
        }
    }

    .catalog-card {
        .prev-img {
            filter: drop-shadow(0px 3px 10px rgba(86, 86, 86, 0.1));
            overflow: hidden;
            margin-bottom: 10px;
        }

        .description {
            margin: 0 30px 20px 30px;
            text-align: center;

            .about {
                @extend %MontserratLight;
                border: 1px solid #8E7E7E;
                background-color: transparent;
                border-radius: 0;
                font-size: 22px;
                line-height: 49px;
                color: #000000;
                min-width: 250px;
                margin-top: 15px;
                @include max-width(lg) {
                    font-size: 20px;
                    line-height: 36px;
                    min-width: 220px;
                }
            }
        }
    }

    #kitchen_infobox {
        padding: 20px 0;
        margin-bottom: 8px;
        @include max-width(lg) {
            padding: 5px 0 15px;
        }

        .price {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                display: block;
                height: 3px;
                width: 80px;
                background-color: #de0000;
                left: 0;
                top: -5px;
            }

            p {
                margin-bottom: 0;
                font-size: 20px;
                color: #de0000;
                @extend %MontserratMedium;
            }
        }

        .params {
            padding-bottom: 16px;
            border-bottom: 2px solid #dbe1e5;

            .param {
                table {
                    word-break: break-all;
                }

                p {
                    margin-bottom: 0;
                    font-size: 16px;
                    @extend %MontserratMedium;

                    span {
                        min-width: 140px;
                        display: inline-block;
                        font-size: 18px;
                        @extend %MontserratSemiBold;
                    }
                }
            }
        }

        .buttons {
            .btn {
                width: 100%;
                margin-top: 16px;
                padding: 0.675rem 0.75rem;
                background-color: #0873d3;
                border-color: #0873d3;
                border-radius: $default-border-radius;
                transition: all 0.3s linear;

                &:hover {
                    box-shadow: 0 0 0 0.2rem rgb(82 161 225 / 50%);
                }

                &:focus, &.focus {
                    box-shadow: none;
                }
            }
        }
    }

    #main_img {
        position: relative;
        margin-bottom: 8px;
        z-index: 1;

        img {
            border-radius: $default-border-radius;
        }

        .main-img-nav {
            position: absolute;
            left: 15px;
            bottom: 15px;
            z-index: 10;

            button {
                border: none;
                transition: all 0.3s linear;
                text-align: center;
                position: relative;
                z-index: 100;
                outline: none;
                width: 55px;
                height: 40px;
                background: rgba(255, 255, 255, 0.6);
                border-radius: 0;
                margin: 0;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    background: white;
                }
            }

            %ButtonArray {
                content: "";
                position: absolute;
                top: 10px;
                left: 16px;
                width: 20px;
                height: 20px;
            }

            .main-img-prev {
                border-right: 1px solid #dfe9f0;

                &:before {
                    @extend %ButtonArray;
                    background: url(../../images/arrow-left.svg) no-repeat;
                }
            }

            .main-img-next:before {
                @extend %ButtonArray;
                background: url(../../images/arrow-right.svg) no-repeat;
            }
        }
    }

    @extend %owl-carousel;

    #kitchen_carousel {
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        @include max-width(lg) {
            padding-left: 0;
            padding-right: 0;
        }

        .item {
            padding: 1px;
            border-radius: $default-border-radius;
            border: 2px solid #d9d9d9;

            img {
                border-radius: $default-border-radius;
            }
        }

        .selected .item {
            border: 2px solid #de0000;
        }

        .owl-nav {
            right: inherit!important;
            left: 15px !important;
            top: -65px !important;
            bottom: inherit!important;
        }
    }

    #descriptive_text {
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }
    }

    #kitchens_backcall {
        margin-bottom: 30px;
        border-radius: $default-border-radius;
        overflow: hidden;
        background: transparent linear-gradient(162deg, rgb(0 106 201 / 95%) 34%, rgb(0 237 223 / 95%)) 0% 0% no-repeat padding-box;
        position: relative;
        padding: 100px 0;
        z-index: 1;
        @include max-width(lg) {
            padding: 50px 0;
        }


        .block {
            display: flex;
            height: 100%;
            margin: 0 40px;
            @include max-width(lg) {
                text-align: center;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.9);
                margin: auto 0;
                @include max-width(lg) {
                    margin: auto;
                }
            }

            .btn {
                @extend %MontserratSemiBold;
                background-color: #fff;
                padding: 12px 40px;
                border-radius: 35px;
                font-size: 24px;
                line-height: 40px;
                color: rgba(#3a3a3a, 0.9);
                text-transform: uppercase;
                margin: auto 0;
                border-color: rgba(255, 255, 255, 0.9);
                transition: all 0.3s linear;
                @include max-width(xl) {
                    font-size: 18px;
                }
                @include max-width(lg) {
                    margin: 30px auto 10px auto;
                    padding: 10px 30px;
                }
                @include max-width(md) {
                    font-size: 16px;
                    padding: 6px 16px;
                }

                &:hover {
                    box-shadow: 0 4px 7px rgb(110 110 110 / 30%);
                }

                &:focus, &.focus {
                    box-shadow: none;
                }
            }
        }
    }
}


