.about-production {
    background-image: url(../../images/about-production-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 550px;
    display: flex;

    > div {
        margin-top: auto;
        margin-bottom: auto;
    }

    h3 {
        font-size: 42px;
        line-height: 63px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.9);
        @include max-width(md) {
            font-size: 30px;
        }
    }

    p {
        font-size: 24px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.9);
        @include max-width(md) {
            font-size: 16px;
        }
    }
}
