.advantages-box {
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 40px;
    box-shadow: 0 0 30px rgb(73 78 92 / 15%);
    border-radius: $default-border-radius;
    background: #fff;

    .icon {
        float: left;
        margin-right: 15px;
        @include max-width(sm) {
            float: none;
            text-align: center;
            margin-right: 0;
            margin-bottom: 10px;
        }

        img {
            width: 70px;
        }
    }

    .title {
        font-size: 26px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.9);
        @include max-width(sm) {
            text-align: center;
        }
    }

    p {
        margin-bottom: 0;
        @include max-width(sm) {
            text-align: center;
        }
    }
}
